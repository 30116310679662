import { localStorageService } from 'core-roblox-utilities';
import {
  TCachedItemDetailsHydratedEntry,
  TDetailEntry,
  ItemDetailsHydrationStatus,
  itemDetailsHydrationServiceTimings,
  itemTypes
} from '../constants/itemDetailsHydrationConstants';

const getLocalStorageKey = (id: number, itemType: string): string => {
  return `ITEM_DETAILS_HYDRATION_${itemType.toUpperCase()}_${id}`;
};

const getLocalStorageItemTrackingKey = (itemType: string): string => {
  return `ITEM_DETAILS_HYDRATION_${itemType.toUpperCase()}_TRACKING`;
};

const createItemDetailsTrackingList = (itemType: string): Array<number> => {
  const newItemDetailsTrackingList = new Array<number>();
  localStorageService.setLocalStorage(
    getLocalStorageItemTrackingKey(itemType),
    newItemDetailsTrackingList
  );

  return newItemDetailsTrackingList;
};

const getLocalStorageItemTrackingList = (itemType: string): Array<number> | undefined => {
  try {
    return localStorageService.getLocalStorage(getLocalStorageItemTrackingKey(itemType)) as
      | undefined
      | Array<number>;
  } catch {
    return undefined;
  }
};

const addItemToLocalStorageItemTrackList = (itemType: string, id: number): void => {
  let itemDetailsTrackingList = getLocalStorageItemTrackingList(itemType);
  if (itemDetailsTrackingList === undefined || itemDetailsTrackingList === null) {
    itemDetailsTrackingList = createItemDetailsTrackingList(itemType);
  }
  itemDetailsTrackingList.push(id);
  localStorageService.setLocalStorage(
    getLocalStorageItemTrackingKey(itemType),
    itemDetailsTrackingList
  );
};

export const getItemDetail = (
  id: number,
  itemType: string
): TCachedItemDetailsHydratedEntry | undefined => {
  try {
    return localStorageService.getLocalStorage(getLocalStorageKey(id, itemType)) as
      | undefined
      | TCachedItemDetailsHydratedEntry;
  } catch {
    return undefined;
  }
};

export const createItemDetailHydrationEntry = (
  id: number,
  itemType: string,
  blockCache?: boolean
): void => {
  if (blockCache) {
    return;
  }
  const newItemDetailEntry = {} as TCachedItemDetailsHydratedEntry;
  newItemDetailEntry.status = ItemDetailsHydrationStatus.DETAILS_LOADING;
  newItemDetailEntry.lastModifiedTimestamp = Date.now();

  localStorageService.setLocalStorage(getLocalStorageKey(id, itemType), newItemDetailEntry);
  addItemToLocalStorageItemTrackList(itemType, id);
};

export const setItemDetailHydrationEntry = (
  id: number,
  itemType: string,
  itemDetails: TDetailEntry,
  blockCache?: boolean
): void => {
  if (blockCache) {
    return;
  }
  const newItemDetailEntry = {} as TCachedItemDetailsHydratedEntry;
  newItemDetailEntry.status = ItemDetailsHydrationStatus.DETAILS_HYDRATED;
  newItemDetailEntry.details = itemDetails;
  newItemDetailEntry.lastModifiedTimestamp = Date.now();
  localStorageService.setLocalStorage(getLocalStorageKey(id, itemType), newItemDetailEntry);

  addItemToLocalStorageItemTrackList(itemType, id);
};

const cleanLocalStorageForItemType = (itemType: string): void => {
  const localStorageTrackingList = getLocalStorageItemTrackingList(itemType);
  const keptCachedItems = new Array<number>();
  if (localStorageTrackingList !== undefined && localStorageTrackingList !== null) {
    localStorageTrackingList.forEach(item => {
      const cachedItemDetal = getItemDetail(item, itemType);
      if (
        cachedItemDetal?.lastModifiedTimestamp === undefined ||
        Date.now() - cachedItemDetal?.lastModifiedTimestamp >
          itemDetailsHydrationServiceTimings.ITEM_DETAIL_CACHE_CLEAR_TIME_TO_LIVE
      ) {
        localStorageService.removeLocalStorage(getLocalStorageKey(item, itemType));
      } else {
        keptCachedItems.push(item);
      }
    });
  }

  localStorageService.setLocalStorage(getLocalStorageItemTrackingKey(itemType), keptCachedItems);
};

export const cleanLocalStorage = (): void => {
  cleanLocalStorageForItemType(itemTypes.asset);
  cleanLocalStorageForItemType(itemTypes.bundle);
};

export const sleep = (milliseconds: number): Promise<void> =>
  new Promise(resolve => setTimeout(() => resolve(undefined), milliseconds));

export default {
  getLocalStorageKey,
  getItemDetail,
  createItemDetailHydrationEntry,
  setItemDetailHydrationEntry,
  cleanLocalStorage,
  sleep
};
